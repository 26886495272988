import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Grid,
  Typography,
  Link as MuiLink,
} from '@material-ui/core';
import { Section } from 'components/organisms';
import { Hero, SidebarArticles } from './components';
import { graphql, Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { Main } from 'layouts';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
  body: {
    fontSize: '1rem',
  },
  bold: {
    fontWeight: '700',
  },
  image: {
    display: 'block',
    margin: '5px auto',
    maxWidth: '100%',
  },
}));

const BlogArticle = ({ data }) => {
  const classes = useStyles();

  const { blog, latest } = data;

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => (
        <Typography variant="h6" gutterBottom className={classes.bold}>
          {text}
        </Typography>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph variant="h6">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: node => (
        <Typography variant="h4" paragraph>
          {node.content[0].value}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: node => (
        <Typography variant="h5" paragraph>
          {node.content[0].value}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: node => (
        <Typography variant="h6" paragraph className={classes.bold}>
          {node.content[0].value}
        </Typography>
      ),
      [INLINES.HYPERLINK]: node => (
        <Typography
          component={MuiLink}
          variant="h6"
          paragraph
          href={node.content[0].value}
          target="_blank"
        >
          {node.content[0].value}
        </Typography>
      ),
      [BLOCKS.EMBEDDED_ASSET]: node => (
        <img
          src={node.data.target.fields.file['en-US'].url}
          className={classes.image}
          alt="imagesf"
        />
      ),
    },
    renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
  };

  return (
    <div className={classes.root}>
      <Main>
        <Box mt={8}>
          <Hero
            cover={blog.cover.fluid}
            title={blog.title}
            subtitle={blog.subtitle}
            date={blog.publishDate}
          />
          <Section>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                md={8}
                container
                className={classes.body}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Link to="/blog-newsroom">
                    <Button variant="outlined" color="primary">
                      Back to List
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  {documentToReactComponents(blog.body.json, options)}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <SidebarArticles data={latest} />
              </Grid>
            </Grid>
          </Section>
        </Box>
      </Main>
    </div>
  );
};

export const query = graphql`
  query GetBlog($id: String) {
    blog: contentfulNewsletter(id: { eq: $id }) {
      id
      publishDate
      title
      subtitle
      body {
        json
      }
      cover {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }

    latest: allContentfulNewsletter(
      sort: { fields: publishDate, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        tags
        id
        publishDate
        cover {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

export default BlogArticle;
